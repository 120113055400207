import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { updateProfile } from "firebase/auth";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import { db, auth } from "../../../config/firebase";
import { validateEmail } from "../../../utils/validate";
import { validatePhoneNumber, validatePassword } from "../../../utils/validate";
import AccountType from "./AccountType";
import DetailInfo from "./DetailInfo";
import CompanyDetail from "./CompanyDetail";
import Done from "./Done";
import { Button } from "../../../components";

const RightPannel = ({ steps, step, setStep }) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);
  const [logoUrl, setLogoUrl] = useState("");
  const navigate = useNavigate();

  const submitStep1 = () => {
    setError("");
    const formData = formRef.current;
    if (!formData.accountType.value) return setError("Select Account Type");
    setStep((prev) => (prev < 4 ? prev + 1 : prev));
  };

  const submitStep2 = () => {
    setError("");
    const formData = formRef.current;
    if (
      !formData.firstName?.value ||
      !formData.lastName?.value ||
      !formData.phoneNumber?.value ||
      !formData.role?.value ||
      !formData.password?.value ||
      !formData.rePassword?.value
    ) {
      return setError("Fill All the required field");
    }

    if (!validatePhoneNumber(formData.phoneNumber?.value))
      return setError("Phone Number should be valid");
    if (!validatePassword(formData.password?.value))
      return setError(
        "Password must be at least 6 characters and must include uppercase letter, lowercase letter, number and special character"
      );
    if (formData.password?.value !== formData.rePassword?.value)
      return setError("Password should be same");

    setStep((prev) => (prev < 4 ? prev + 1 : prev));
  };

  const submitStep3 = () => {
    const formData = formRef.current;
    setError("");
    if (
      !formData.companyName?.value ||
      !formData.email?.value ||
      !formData.website?.value
    )
      return setError("Fill all the required fields");

    if (!validateEmail(formData.email?.value))
      return setError("Enter a valid email");

    // website validation

    if (!formData.acceptTerms?.checked)
      return setError("Accept the term and condition");

    createAuthWithEmailAndPass();
  };

  const createAuthWithEmailAndPass = async () => {
    setLoading(true);
    const formData = formRef.current;

    try {
      const user = await createUserWithEmailAndPassword(
        auth,
        formData.email?.value,
        formData.password?.value
      );
      const adminDate = {
        companyEmail: formData.email?.value,
        companyName: formData.companyName?.value,
        companyUrl: formData.website?.value,
        contactPersonFirstName: formData.firstName.value,
        contactPersonLastName: formData.lastName.value,
        accountType: formData.accountType.value,
        employeeSize: formData.employeSize?.value,
        logoUrl: logoUrl,
        phoneNumber: formData.phoneNumber.value,
        role: formData.role.value,
        createdAt: Timestamp.now(),
        modifiedAt: Timestamp.now(),
        uid: user?.user?.uid,
      };

      await updateProfile(auth?.currentUser, {
        displayName: formData.companyName?.value,
      });
      await addDoc(collection(db, "adminProfile"), adminDate);
      setStep((prev) => (prev < 4 ? prev + 1 : prev));
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const loginCandidate = () => {
    setError("");
    navigate("/admin");
  };

  return (
    <div className="w-[60%] h-full p-10">
      <div className="w-full text-sm font-medium flex justify-end pr-8">
        Already Have an account ?
        <Link to="/admin" className="text-[#892bcc] underline ml-2 ">
          Login
        </Link>
      </div>
      <div className="w-full h-full flex flex-col items-center overflow-y-scroll scrollable">
        <div className="relative w-2/3 flex items-start my-12 justify-between">
          <div className="absolute w-[90%] ml-6 top-6 h-[2px] -z-10 bg-[#892bcc]/20">
            <div
              className="h-full bg-[#892bcc]"
              style={{ width: `${((step - 1) * 100) / (steps.length - 1)}%` }}
            />
          </div>
          {steps.map((stepItem, index) => {
            return (
              <div
                key={stepItem.value}
                className="flex flex-col items-center justify-center w-[84px]"
              >
                <div className="bg-white">
                  <div
                    className={`flex justify-center items-center w-12 h-12 border-[1px] border-[#892bcc] rounded-md
                    ${step > index ? "" : "opacity-40"}`}
                  >
                    <img src={stepItem.icon} alt={step.label} width="20px" />
                  </div>
                </div>

                <div className="text-xs mt-2 text-center">{stepItem.label}</div>
              </div>
            );
          })}
        </div>

        <form ref={formRef} className="w-2/3 flex flex-col gap-6">
          <div className={step !== 1 ? "hidden" : ""}>
            <AccountType />
          </div>
          <div className={step !== 2 ? "hidden" : ""}>
            <DetailInfo />
          </div>
          <div className={step !== 3 ? "hidden" : ""}>
            <CompanyDetail logoUrl={logoUrl} setLogoUrl={setLogoUrl} />
          </div>
          <div className={step !== 4 ? "hidden" : ""}>
            <Done />
          </div>

          <div className="space-y-2">
            <div className="text-xs text-red-500 min-h-3">{error}</div>
            <div
              className={`w-full flex gap-3 ${
                step === 4 ? "justify-center" : ""
              }`}
            >
              {(step === 2 || step === 3) && (
                <Button
                  handleClick={() =>
                    setStep((prev) => (prev > 1 ? prev - 1 : prev))
                  }
                  text="Back"
                  version="outline-primary"
                  width="10rem"
                />
              )}
              <Button
                handleClick={
                  step === 1
                    ? submitStep1
                    : step === 2
                    ? submitStep2
                    : step === 3
                    ? submitStep3
                    : loginCandidate
                }
                text={step === 3 ? "Submit" : step === 4 ? "Login" : "Next"}
                width="10rem"
                loading={loading}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RightPannel;
