import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { FaImage } from "react-icons/fa";
import { languageOptions } from "../../../constant/LanguageOptions";
import {
  query,
  collection,
  onSnapshot,
  addDoc,
  updateDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../../config/firebase";
import { useLocation } from "react-router-dom";

import { Button, TextInput, SelectInput } from "../../../components";
import CreatableSelect from "react-select/creatable";
import { Timestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ImageUpload from "./ImageUpload";

export const UploadSingleQuestion = ({
  updateQuestion,
  setQuestionEditOpen,
}) => {
  const [questionType, setQuestionType] = useState();
  const [formDetail, setFormDetail] = useState({
    questionName: "",
    statment: "",
    answerSummary: "",
    difficulty: "",
    time: "",
    answer: "",
    sampleInput: "",
    sampleOutput: "",
    defaultCode: "",
    constraints: "",
    testCases: "",
    expectedOutput: "",
  });
  const [language, setLanguage] = useState();

  const [collectionName, setCollectionName] = useState("questionLibrary");

  const [questionLibCategoryData, setQuestionLibCategoryData] = useState();
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();

  const [skills, setSkills] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState([]);

  const [topics, setTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState([]);
  // const [editQuestioId,setEditQuestionId] = useState()

  // const [currentDocumentId, setCurrentDocumentId] = useState();

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const qusUploadFormRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();
  const urlparams = new URLSearchParams(location.search);
  const libraryTypeParam = urlparams.get("libraryType");
  const editDocidParam = urlparams.get("editDocid");

  useEffect(() => {
    setCollectionName(
      libraryTypeParam === "Custom"
        ? "customQuestionLibrary"
        : "questionLibrary"
    );
  }, [libraryTypeParam, editDocidParam]);

  const optionInputs = [
    {
      placeholder: "Option A",
      name: "optionA",
    },
    {
      placeholder: "Option B",
      name: "optionB",
    },
    {
      placeholder: "Option C",
      name: "optionC",
    },
    {
      placeholder: "Option D",
      name: "optionD",
    },
  ];

  useEffect(() => {
    const fetchQuestionCategoryData = async () => {
      const q = query(collection(db, "questionLibraryCategories"));
      onSnapshot(q, (querySnapshot) => {
        const categorydata = [];
        querySnapshot.forEach((doc) => {
          categorydata.push({ id: doc.id, ...doc.data() });
        });

        setQuestionLibCategoryData(categorydata);
        setCategory(
          categorydata?.map((data) => {
            return {
              label: data.name.charAt(0).toUpperCase() + data.name.slice(1),
              value: data.name,
            };
          })
        );
      });
    };

    fetchQuestionCategoryData();
  }, []);

  useEffect(() => {
    const catSkill = questionLibCategoryData?.filter((data) =>
      selectedCategory?.some((selectedCat) => selectedCat.value === data.name)
    );
    const allSkillData = catSkill?.map((data) => data.skills).flat();
    const uniqueSkillData =
      allSkillData &&
      Object.values(
        allSkillData?.reduce((acc, current) => {
          // Use the skill as the key to check uniqueness
          const key = current.skill;
          // If the skill hasn't been encountered yet, add it to the accumulator
          if (!acc[key]) {
            acc[key] = { ...current };
          }
          // Merge topics if the skill has already been encountered
          else if (current.topics) {
            acc[key].topics = [
              ...new Set([...acc[key].topics, ...current.topics]),
            ];
          }
          return acc;
        }, {})
      );
    // console.log(uniqueSkillData);
    // console.log(catSkill?.map((data) => data.skills).flat());
    setSkills(catSkill && uniqueSkillData);

    if (selectedSkill) {
      const topic = catSkill
        ?.map((data) => data.skills)
        .flat()
        .filter((skil) =>
          selectedSkill?.some((selskil) => selskil.value === skil.skill)
        )
        .map((topic) => topic.topics)
        .flat();
      // console.log(topics)
      setTopics(topic);
    }
    // co
  }, [selectedCategory, selectedSkill, questionLibCategoryData, selectedTopic]);
  // console.log(qusUploadFormRef.current.programmingLang?.value, language);

  const handleUpload = async (e) => {
    e.preventDefault();
    setError("");

    const formData = qusUploadFormRef.current;
    // console.log(formData);
    setLoading(true);

    // console.log(selectedTopic);
    const updateQuestionData = {
      category: selectedCategory.map((data) => data.value),
      skills: selectedSkill.map((data) => data.value),
      topic: selectedTopic.map((data) => data.value),
      options:
        questionType.value === "mcq"
          ? optionInputs.map((option) => formData[option.name]?.value)
          : [],
      difficulty: Number(formData.difficulty?.value),
      statement: formData.statment?.value,
      type: formData.questionType?.value,
      correctOption:
        questionType.value === "mcq"
          ? formData[`option${formData?.answer?.value}`]?.value
          : [],
      isDeleted: false,
      answerSummary:
        questionType.value === "subjective"
          ? formData?.answerSummary?.value
          : "",
      createdAt: Timestamp.now(),
      questionName: formData.questionName?.value,
      time: Number(formData?.time?.value * 60),
      sampleInput: formData.sampleInput?.value
        ? formData.sampleInput?.value
        : "",
      sampleOutput: formData.sampleOutput?.value
        ? formData.sampleOutput?.value
        : "",
      defaultCode: formData.defaultCode?.value
        ? formData.defaultCode?.value
        : "",
      constraints: formData.constraints?.value
        ? formData.constraints?.value
        : "",
      testCases: formData.testCases?.value ? formData.testCases?.value : "",
      expectedOutput: formData.expectedOutput?.value
        ? formData.expectedOutput?.value
        : "",
      language: language ? language : "",
    };

    // console.log(questionData);
    // console.log(updateQuestionData);
    // if(editDocidParam){
    //   try {
    //     const docRef = doc(db, "customQuestionLibrary", editDocidParam);
    //     const res = await updateDoc(docRef, updateQuestionData);
    //     // console.log('Document updated successfully!');
    //     console.log("working", res);

    //     console.log("working", res);
    //     toast.success("Success! Question Updated");

    //     // setQuestionUpdate(true);
    //   } catch (error) {
    //     // console.error('Error updating document:', error);
    //     toast.error(`Error! Unable to Updte Question. (${error.message})`);
    //   }
    // }else{
    try {
      await addDoc(collection(db, collectionName), updateQuestionData);
      navigate(`/questionLibrary?libraryType=${libraryTypeParam}`);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
    setLoading(false);
    // } else {
    //   setError("All fields are required.");
    // }
  };
  // }

  const createNewCategory = async (inputValue) => {
    const newValue = {
      value: inputValue.toLowerCase(),
      label: inputValue,
    };
    setSelectedCategory([newValue]);
    const createNewCategoryData = {
      createdAt: Timestamp.now(),
      name: inputValue,
      skills: [],
    };
    // console.log(createNewCategoryData)
    try {
      await addDoc(
        collection(db, "questionLibraryCategories"),
        createNewCategoryData
      );
    } catch (error) {
      console.log(error.message);
    }
  };

  const createNewSkill = async (inputValue) => {
    const newValue = {
      value: inputValue.toLowerCase(),
      label: inputValue,
    };
    setSelectedSkill([...selectedSkill, newValue]);
    const catSkill = questionLibCategoryData?.filter((data) =>
      selectedCategory?.some((selectedCat) => selectedCat.value === data.name)
    );
    console.log(catSkill);
    // let updateSkillValue = [];
    for (let i = 0; i < catSkill.length; i++) {
      const createNewSkillData = {
        modifiedAt: Timestamp.now(),
        skills: [
          ...catSkill[i].skills,
          { skill: inputValue.toLowerCase(), topics: [] },
        ],
      };
      try {
        const userRef = doc(db, "questionLibraryCategories", catSkill[i].id);
        await updateDoc(userRef, createNewSkillData);
      } catch (error) {
        console.error("Error updating document: ", error);
      }
    }
  };

  const createNewTopic = async (inputValue) => {
    const newValue = {
      value: inputValue.toLowerCase(),
      label: inputValue,
    };
    setSelectedTopic([...selectedTopic, newValue]);

    const catSkill = questionLibCategoryData?.filter((data) =>
      selectedCategory?.some((selectedCat) => selectedCat.value === data.name)
    );

    for (let i = 0; i < catSkill.length; i++) {
      let updateTopics = [];
      for (let j = 0; j < catSkill[i].skills.length; j++) {
        if (
          selectedSkill.some(
            (data) => data.value === catSkill[i].skills[j].skill
          )
        ) {
          const existingSkills =
            catSkill[i].skills[j].topics?.length > 0
              ? [catSkill[i].skills[j].topics, inputValue.toLowerCase()]
              : [inputValue];
          // console.log(existingSkills);
          updateTopics.push({
            skill: catSkill[i].skills[j].skill,
            topics: existingSkills.flat(),
          });
        } else {
          // let existingSkill =
          updateTopics.push({
            skill: catSkill[i].skills[j].skill,
            topics: catSkill[i].skills[j].topics,
          });
        }
      }
      // console.log({ modifiedAt: Timestamp.now(), skills: updateTopics });
      try {
        const userRef = doc(db, "questionLibraryCategories", catSkill[i].id);
        await updateDoc(userRef, {
          modifiedAt: Timestamp.now(),
          skills: updateTopics,
        });
      } catch (error) {
        console.error("Error updating document: ", error);
      }
    }
  };
  console.log(questionLibCategoryData);

  const [editDocData, setEditDocData] = useState();

  // set edit question Data in form

  const showEditQuestionInForm = async () => {
    // console.log("working")
    try {
      // const documentRef = doc(db, collectionName, editDocidParam);
      // const docSnapshot = await getDoc(documentRef);
      //  console.log('working',docSnapshot.data())
      // if (docSnapshot.exists()) {
      // if (docSnapshot.exists()) {

      console.log("working", updateQuestion);
      // const docData = docSnapshot.data();
      // setCollectionName(updateQuestion.editDocid)
      setCollectionName(
        updateQuestion.libraryType === "Custom"
          ? "customQuestionLibrary"
          : "questionLibrary"
      );
      setEditDocData(updateQuestion);
      setQuestionType({
        label:
          updateQuestion.type.charAt(0).toUpperCase() +
          updateQuestion.type.slice(1),
        value: updateQuestion.type,
      });

      setSelectedCategory(
        updateQuestion.category.map((data) => {
          return { label: data, value: data };
        })
      );
      setSelectedSkill(
        updateQuestion.skills?.map((data) => {
          return { label: data, value: data };
        })
      );

      setSelectedTopic(
        updateQuestion.topic?.map((data) => {
          return { label: data, value: data };
        })
      );
      const alphabat = ["A", "B", "C", "D"];
      const correctAnswer =
        updateQuestion.options &&
        updateQuestion.options
          .map((data, i) => {
            if (data === updateQuestion.correctOption) {
              return alphabat[i];
            } else {
              return null;
            }
          })
          ?.filter((data) => data !== null);

      console.log(correctAnswer, updateQuestion.correctOption);
      setFormDetail({
        questionName: updateQuestion.questionName,
        statment: updateQuestion.statement,
        answerSummary: updateQuestion.answerSummary,
        difficulty: updateQuestion.difficulty,

        time: updateQuestion.time / 60,
        // answer: updateQuestion.correctOption,
        answer: correctAnswer[0]
          ? correctAnswer[0]
          : updateQuestion.correctOption
          ? updateQuestion.correctOption
          : "",
        optionA: updateQuestion.options[0],
        optionB: updateQuestion.options[1],
        optionC: updateQuestion.options[2],
        optionD: updateQuestion.options[3],
        sampleInput: updateQuestion.sampleInput,
        sampleOutput: updateQuestion.sampleOutput,
        defaultCode: updateQuestion.defaultCode,
        constraints: updateQuestion.constraints,
        testCases: updateQuestion.testCases,
        expectedOutput: updateQuestion.expectedOutput,
        programmingLanguage: {
          label: updateQuestion.difficulty,
          value: updateQuestion.difficulty,
        },
      });
      // }
    } catch (error) {
      console.error("Error fetching document:", error);
    }
  };

  useEffect(() => {
    console.log(updateQuestion);
    if (updateQuestion) showEditQuestionInForm();
    // setEditQuestionId(editDocidParam)
  }, [updateQuestion]);

  // console.log(editDocidParam);

  // update the question Data......

  const handleUpdateQuestion = async (e) => {
    e.preventDefault();
    const formData = qusUploadFormRef.current;
    // console.log(formData);
    setLoading(true);

    // console.log(selectedTopic);
    const updateQuestionData = {
      category: selectedCategory.map((data) => data.value),
      skills: selectedSkill.map((data) => data.value),
      topic: selectedTopic.map((data) => data.value),
      options:
        questionType.value === "mcq"
          ? optionInputs.map((option) => formData[option.name]?.value)
          : [],
      difficulty: Number(formData.difficulty?.value),
      statement: formData.statment?.value,
      type: formData.questionType?.value,
      correctOption:
        questionType.value === "mcq"
          ? formData[`option${formData?.answer?.value}`]?.value
          : [],
      isDeleted: false,
      answerSummary:
        questionType.value === "subjective"
          ? formData?.answerSummary?.value
          : "",
      createdAt: updateQuestion.createdAt,
      updatedAt: Timestamp.now(),
      questionName: formData.questionName?.value,
      time: Number(formData?.time?.value * 60),
      sampleInput: formData.sampleInput?.value
        ? formData.sampleInput?.value
        : "",
      sampleOutput: formData.sampleOutput?.value
        ? formData.sampleOutput?.value
        : "",
      defaultCode: formData.defaultCode?.value
        ? formData.defaultCode?.value
        : "",
      constraints: formData.constraints?.value
        ? formData.constraints?.value
        : "",
      testCases: formData.testCases?.value ? formData.testCases?.value : "",
      expectedOutput: formData.expectedOutput?.value
        ? formData.expectedOutput?.value
        : "",
      language: language ? language : "",
    };
    // console.log("handle Update Question", updateQuestionData);
    // console.log("collaction Name", collectionName, updateQuestion.editDocid);
    try {
      const docRef = doc(db, collectionName, updateQuestion.editDocid);
      await updateDoc(docRef, updateQuestionData);

      toast.success("Success! Question Updated");
      setQuestionEditOpen(false);
      // setQuestionUpdate(true);
    } catch (error) {
      toast.error(`Error! Unable to Updte Question. (${error.message})`);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="w-full h-full overflow-y-scroll scrollable">
      {/* // <div className="grid grid-cols-2" > */}
      <form
        ref={qusUploadFormRef}
        // className="flex flex-col justify-center items-center gap-2"
        onSubmit={
          updateQuestion?.editDocid ? handleUpdateQuestion : handleUpload
        }
      >
        <div className="w-full px-8">
          <h1 className="heading2">
            {!updateQuestion?.editDocid && "Upload Single Question"}
          </h1>
        </div>
        <div className="grid grid-cols-2 gap-3 w-full px-8">
          {/* <div className="flex flex-col sm:flex-row gap-2"> */}
          <div className="">
            <label className="text-xs mb-1 text-[#888]">
              Question Type<sup>*</sup>
            </label>

            <SelectInput
              selectOptions={[
                { value: "mcq", label: "MCQ" },
                { value: "subjective", label: "Subjective" },
                { value: "code", label: "Code" },
              ]}
              inputName="questionType"
              value={questionType}
              handleChange={(selectedValue) => setQuestionType(selectedValue)}
              placeholderText="Question Type"
              isClearable
              isRequired
            />
          </div>
          <div className="">
            <label className="text-xs mb-1 text-[#888]">
              Category<sup>*</sup>
            </label>

            <CreatableSelect
              styles={{ backgroundColor: "lightblue" }}
              options={category}
              value={selectedCategory}
              name="category"
              onChange={(selectedValue) => setSelectedCategory(selectedValue)}
              placeholder="Select Category"
              isClearable
              isMulti
              // menuIsOpen={selectedCategory}
              onCreateOption={(inputValue) => createNewCategory(inputValue)}
            />
          </div>
          {/* </div> */}
          {/* <div> */}
          {/* <div> */}
          {/* <div className="flex flex-col sm:flex-row gap-2"> */}
          <div className={`w-full ${selectedCategory ? "" : "opacity-40"}`}>
            <label className="text-xs mb-1 text-[#888]">
              Skills<sup>*</sup>
            </label>
            <CreatableSelect
              options={skills?.map((catskil) => {
                return {
                  label:
                    catskil.skill?.charAt(0).toUpperCase() +
                    catskil.skill?.slice(1),
                  value: catskil.skill,
                };
              })}
              name="skills"
              value={selectedSkill}
              onChange={(selectedValue) => setSelectedSkill(selectedValue)}
              placeholder="Select Skill"
              isClearable
              isMulti
              isDisabled={!selectedCategory}
              onCreateOption={(inputValue) => createNewSkill(inputValue)}
            />
          </div>
          <div
            className={`w-full ${selectedSkill.length > 0 ? "" : "opacity-40"}`}
          >
            <label className="text-xs mb-1 text-[#888]">
              Topic<sup>*</sup>
            </label>
            <CreatableSelect
              options={topics?.map((skil) => {
                return {
                  label: skil?.charAt(0).toUpperCase() + skil?.slice(1),
                  value: skil,
                };
              })}
              name="topic"
              value={selectedTopic}
              isMulti
              onChange={(selectedValue) => setSelectedTopic(selectedValue)}
              placeholder="Select Topic"
              isClearable
              isDisabled={!selectedSkill.length > 0}
              onCreateOption={(inputValue) => createNewTopic(inputValue)}
            />
          </div>
          {/* </div> */}

          <div className="mt-5  col-span-2">
            <label className="text-xs mb-1 text-[#888]">
              Question Name <sup>*</sup>{" "}
            </label>
            <TextInput
              type="text"
              inputName="questionName"
              textRows={1}
              isRequired={true}
              placeholderText="Question Name"
              inputValue={formDetail.questionName}
              handleChange={(e) => {
                setFormDetail({
                  ...formDetail,
                  [e.target.name]: e.target.value,
                });
              }}
            />
          </div>

          <div className="mt-4 col-span-2 ">
            <label className="text-xs mb-1 text-[#888]">
              {questionType?.value === "code"
                ? "Problem Statment"
                : "Question Statement"}
              <sup>*</sup>
            </label>
            <TextInput
              type="textbox"
              textRows="4"
              inputName="statment"
              placeholderText={
                questionType?.value === "code"
                  ? "Problem Statment"
                  : "Question Statement"
              }
              isRequired={true}
              inputValue={formDetail.statment}
              handleChange={(e) => {
                setFormDetail({
                  ...formDetail,
                  [e.target.name]: e.target.value,
                });
              }}
            />
            {/* <ImageUpload setImageUrl={setImageUrl} /> */}
          </div>
          {questionType?.value === "mcq" ? (
            <div className="mt-2 col-span-2">
              <label className="text-xs mb-1 text-[#888]">
                Options<sup>*</sup>
              </label>
              <div className="grid grid-cols-2 gap-2">
                {[0, 2].map((i) => {
                  return (
                    <div key={i}>
                      {optionInputs
                        .slice(i, i + 2)
                        .map((optionInput, index) => {
                          return (
                            <div className="my-2" key={index}>
                              <TextInput
                                key={optionInput.placeholder}
                                placeholderText={optionInput.placeholder}
                                inputName={optionInput.name}
                                inputValue={formDetail[optionInput.name]}
                                handleChange={(e) => {
                                  setFormDetail({
                                    ...formDetail,
                                    [e.target.name]: e.target.value,
                                  });
                                }}
                                innerLabel={
                                  optionInput.placeholder.slice(-1) + "."
                                }
                              />
                            </div>
                          );
                        })}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : questionType?.value === "subjective" ? (
            <div className="col-span-2">
              <label className="text-xs mb-1 text-[#888]">
                Answer Summary<sup>*</sup>
              </label>
              <TextInput
                type="textbox"
                inputName="answerSummary"
                textRows="4"
                placeholderText="A brief Summary of the answer"
                isRequired={questionType?.value === "subjective"}
                inputValue={formDetail.answerSummary}
                handleChange={(e) => {
                  setFormDetail({
                    ...formDetail,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </div>
          ) : questionType?.value === "code" ? (
            <>
              {/* <div className="col-span-2 gap-2"> */}
              <div>
                <label className="text-xs mb-1 text-[#888]">
                  Sample Input<sup>*</sup>
                </label>
                <TextInput
                  type="textbox"
                  textRows="2"
                  inputName="sampleInput"
                  placeholderText="Sample input"
                  isRequired={questionType.value === "code"}
                  inputValue={formDetail.sampleInput}
                  handleChange={(e) => {
                    setFormDetail({
                      ...formDetail,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </div>
              <div>
                <label className="text-xs mb-1 text-[#888]">
                  Sample output<sup>*</sup>
                </label>
                <TextInput
                  type="textbox"
                  textRows="2"
                  inputName="sampleOutput"
                  placeholderText="Sample output"
                  isRequired={questionType.value === "code"}
                  inputValue={formDetail.sampleOutput}
                  handleChange={(e) => {
                    setFormDetail({
                      ...formDetail,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </div>
              {/* </div> */}
              {/* <div className="flex flex-col md:flex-row gap-2"> */}
              <div>
                <label className="text-xs mb-1 text-[#888]">Default Code</label>
                <TextInput
                  type="textbox"
                  inputName="defaultCode"
                  textRows="2"
                  placeholderText="Default Code"
                  inputValue={formDetail.defaultCode}
                  handleChange={(e) => {
                    setFormDetail({
                      ...formDetail,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </div>
              <div>
                <label className="text-xs mb-1 text-[#888]">Constraints</label>
                <TextInput
                  type="textbox"
                  textRows="2"
                  inputName="constraints"
                  placeholderText="constraints"
                  inputValue={formDetail.constraints}
                  handleChange={(e) => {
                    setFormDetail({
                      ...formDetail,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </div>
              {/* </div> */}
              {/* <div className="flex flex-col md:flex-row gap-2"> */}
              <div>
                <label className="text-xs mb-1 text-[#888]">Test Cases</label>
                <TextInput
                  type="textbox"
                  textRows="2"
                  inputName="testCases"
                  placeholderText="Test Cases"
                  inputValue={formDetail.testCases}
                  handleChange={(e) => {
                    setFormDetail({
                      ...formDetail,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </div>
              <div>
                <label className="text-xs mb-1 text-[#888]">
                  Expected Output
                </label>
                <TextInput
                  type="textbox"
                  textRows="2"
                  inputName="expectedOutput"
                  placeholderText="Expected Output"
                  inputValue={formDetail.expectedOutput}
                  handleChange={(e) => {
                    setFormDetail({
                      ...formDetail,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </div>
              {/* </div> */}
            </>
          ) : (
            <></>
          )}
          {/* <div> */}
          {questionType?.value === "mcq" && (
            <div>
              <label className="text-xs mb-1 text-[#888]">
                Correct Option<sup>*</sup>
              </label>
              <div>
                <TextInput
                  type="select"
                  // idName="answer"
                  // width="100%"
                  // inputName="questionType"
                  inputName="answer"
                  placeholderText="Select Correct Answer"
                  isRequired={true}
                  inputValue={formDetail.answer}
                  handleChange={(e) => {
                    setFormDetail({
                      ...formDetail,
                      [e.target.name]: e.target.value,
                    });
                  }}
                  // inputValue={answer}
                  // handleChange={(e) => setAnswer(e.target.value)}
                  selectOptions={["A", "B", "C", "D"]}
                />
              </div>
            </div>
          )}
          {questionType?.value === "code" && (
            <div>
              <label className="text-xs mb-1 text-[#888]">
                Programming Language
              </label>
              <SelectInput
                placeholderText={`Select programming language`}
                selectOptions={languageOptions}
                inputName="programmingLanguage"
                // handleChange={(selectedOption) => setLanguage(selectedOption)}
                value={formDetail?.programmingLanguage}
                handleChange={(e) => {
                  setFormDetail({
                    ...formDetail,
                    programmingLanguage: e,
                  });
                }}
                isClearable={true}
                isSearchable={true}
              />
            </div>
          )}
          {/* </div> */}
          {/* <div className="flex flex-col md:flex-row gap-2 mt-3"> */}
          <div>
            <label className="text-xs my-1 text-[#888]">
              Question Difficulty<sup>*</sup>
            </label>

            <SelectInput
              selectOptions={[
                { value: 1, label: "Easy" },
                { value: 2, label: "Medium" },
                { value: 3, label: "Hard" },
              ]}
              // value={questionDifficulty}
              inputName="difficulty"
              value={{
                value: formDetail.difficulty,
                label:
                  formDetail.difficulty === 1
                    ? "Easy"
                    : formDetail.difficulty === 2
                    ? "Medium"
                    : formDetail.difficulty === 3
                    ? "Hard"
                    : formDetail.difficulty,
              }}
              handleChange={(e) => {
                setFormDetail({
                  ...formDetail,
                  difficulty: e.value,
                });
              }}
              // handleChange={(selectedValue) =>
              //   setQuestionDifficulty(selectedValue)
              // }
              placeholderText="Question difficulty"
              isClearable
              isRequired
            />
          </div>
          <div>
            <label className="text-xs my-1 text-[#888]">
              Alloted Time (min) <sup>*</sup>
            </label>
            <TextInput
              type="number"
              placeholderText="Question Duration (in minutes)"
              // inputValue={time}
              inputName="time"
              inputValue={formDetail.time}
              handleChange={(e) => {
                setFormDetail({
                  ...formDetail,
                  [e.target.name]: e.target.value,
                });
              }}
              isRequired={true}
            />
          </div>
          {/* </div> */}
          {/* {error && (
            <div className="text-red-500 text-center text-sm">{error}</div>
          )} */}
          <div className="w-full flex mt-4 items-center justify-end col-span-2 gap-2">
            {/* <Button
                  text="Preview"
                  version="outline-primary"
                  className="mr-4"
                /> */}
            {updateQuestion?.editDocid && (
              <Button
                version="secondary"
                handleClick={() => setQuestionEditOpen(false)}
                text="Cancel"
                width="12rem"
                isDisabled={loading}
              />
            )}
            <Button
              type="submit"
              text={updateQuestion?.editDocid ? "Save" : "Upload"}
              width="12rem"
              loading={loading}
            />
          </div>
        </div>
      </form>
    </div>
  );
};
