import React, { useEffect, useState } from "react";
import LibraryAssessment from "./LibraryAssessment";
import CustomAssessment from "./CustomAssessment";
import { useLocation } from "react-router-dom";

const NewAssessment = () => {
  const location = useLocation();
  const urlparams = new URLSearchParams(location.search);
  const nameParam = urlparams.get("name");
  const positionParam = urlparams.get("position");
  const experienceParam = urlparams.get("experience");
  const domainParam = urlparams.get("domain");
  const typeParam = urlparams.get("type");
  const editIdParam = urlparams.get("editId");

  const [assessmentDetails, setAssessmentDetails] = useState();

  useEffect(() => {
    setAssessmentDetails({
      name: nameParam,
      position: positionParam,
      experience: experienceParam,
      domain: domainParam,
      type: typeParam,
      editDocId: editIdParam ? editIdParam : "",
    });
  }, [
    nameParam,
    positionParam,
    experienceParam,
    domainParam,
    typeParam,
    editIdParam,
  ]);

  return (
    <div className="main-container w-full mx-auto md:ml-20 mt-1 flex flex-col lg-px-10">
      <div className="card px-6 py-4 w-full lg:h-[90vh] overflow-hidden">
        <>
          {typeParam === "custom" ? (
            <CustomAssessment
              assessmentDetails={assessmentDetails}
              // admin={admin}
            />
          ) : (
            <LibraryAssessment
              assessmentDetails={assessmentDetails}
              // admin={admin}
            />
          )}
        </>
      </div>
    </div>
  );
};

export default NewAssessment;
