export const validateEmail = (email) => {
  // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

export const validatePhoneNumber = (number) => {
  const phoneRegex = /^\d{10}$/g;
  return phoneRegex.test(number);
};

// Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and a special character.
export const validatePassword = (password) => {
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_-])[A-Za-z\d@$!%*?&_-]{6,}$/;
  return regex.test(password);
};
