import React from "react";

const Logo = ({ width }) => {
  return (
    <div className="logo flex justify-center items-center">
      <img src="/img/logo_full.png" alt="logo" style={{ width: width }} />
    </div>
  );
};
Logo.defaultProps = {
  width: "fit",
};

export default Logo;
