import React, { useEffect, useState } from "react";
import { Button } from "../../components";
import AssessmentsListing from "./AssessmentsListing";
import AssessmentView from "./assessmentView.jsx/AssessmentView";
import NewAssessmentPopup from "./newAssessment/NewAssessmentPopup";
import { db } from "../../config/firebase";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { doc, updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { auth } from "../../config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

const Assessments = () => {
  const [userAuthState] = useAuthState(auth);
  const [openAssessmentView, setOpenAssessmentView] = useState(false);
  const [assessments, setAssessments] = useState(null);
  const [deleteAssessmentId, setDeleteAssessmentId] = useState(null);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const urlparams = new URLSearchParams(location.search);
  const idParam = urlparams.get("id");

  useEffect(() => {
    if (idParam) {
      setOpenAssessmentView(true);
    }
  }, [idParam]);

  useEffect(() => {
    const fetchAssessmentsData = () => {
      if (auth?.currentUser?.displayName) {
        setLoading(true);
        let q = query(
          collection(db, "assessments"),
          where("companyName", "==", auth?.currentUser.displayName),
          where("isDeleted", "==", false)
        );

        const querySnapshot = onSnapshot(q, (querySnapshot) => {
          const assessmentsData = [];
          querySnapshot.forEach((doc) => {
            assessmentsData.push({ id: doc.id, ...doc.data() });
          });
          setAssessments(assessmentsData);
        });
        setLoading(false);
        return querySnapshot;
      }
    };
    userAuthState && fetchAssessmentsData();
  }, [userAuthState]);

  useEffect(() => {
    const deleteAssessmentthroughId = async () => {
      // const documentId = deleteAssessment?.id;
      const documentId = deleteAssessmentId;
      const documentRef = doc(db, "assessments", documentId);
      try {
        await updateDoc(documentRef, { isDeleted: true });
        toast.success("Success! Your Assessment has been deleted");
      } catch (error) {
        toast.error(`Error! ${error.message}`);
      }
    };
    if (deleteAssessmentId) {
      deleteAssessmentthroughId();
    }
  }, [deleteAssessmentId]);

  const [showNewAssessmentModule, setShowNewAssessmentModule] = useState(false);
  // console.log(deleteAssessmentId);
  return (
    <div className="main-container w-full mx-auto md:ml-20 mt-1 flex flex-col lg-px-10">
      <div className="card p-2 pr-0 w-full relative lg:h-[90vh] overflow-y-hidden">
        <div className="application-filter-bar flex gap-2 h-[10%] pr-6">
          <div className="left-item w-full flex items-center gap-3 py-2 px-4 overflow-x-auto scrollbar-none">
            <h1 className="heading1 ml-2">All Assessments</h1>
          </div>
          <div className="right-item">
            <div className="invite-candidate flex items-center justify-center py-2">
              <Button
                width="200px"
                text=""
                handleClick={() => setShowNewAssessmentModule(true)}
              >
                <div> New Assessment </div>
              </Button>
            </div>
          </div>
        </div>
        <div className="w-[100%] overflow-x-scroll h-[90%] px-4 py-2 scrollable">
          <AssessmentsListing
            loading={loading}
            assessments={assessments}
            setDeleteAssessmentId={setDeleteAssessmentId}
            setOpenAssessmentView={setOpenAssessmentView}
          />
        </div>
      </div>
      {openAssessmentView && (
        <AssessmentView
          openAssessmentView={openAssessmentView}
          setDeleteAssessmentId={setDeleteAssessmentId}
          setOpenAssessmentView={setOpenAssessmentView}
        />
      )}
      {showNewAssessmentModule && (
        <NewAssessmentPopup
          setShowNewAssessmentModule={setShowNewAssessmentModule}
        />
      )}
    </div>
  );
};

export default Assessments;
