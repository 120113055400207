import React from "react";

import { Button } from "..";

const ConfirmationModule = ({
  confirmationMessage,
  confirmationButton,
  cancelAlert,
  confirmSkip,
  confirmSubmit,
}) => {
  return (
    <div className="card flex flex-col justify-center items-center p-[50px] relative">
      <div
        onClick={cancelAlert}
        className="flex justify-center items-center absolute top-5 right-5 rounded-full w-10 h-10 bg-[#F9FAFB] cursor-pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="w-7 h-7"
        >
          <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
        </svg>
      </div>

      <p className="font-semibold text-xl mb-5">{confirmationMessage[0]}</p>
      <p className="text-sm text-[#919397]">{confirmationMessage[1]}</p>

      <div className="flex mt-8 gap-5">
        <Button
          text={confirmationButton[0]}
          idName="confirmSkip"
          version="secondary"
          handleClick={
            confirmationButton[0] === "Skip Question"
              ? confirmSkip
              : confirmSubmit
          }
        />
        <Button text={confirmationButton[1]} handleClick={cancelAlert} />
      </div>
    </div>
  );
};

export default ConfirmationModule;
